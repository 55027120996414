export default{
    create_role: "Crear rol",
    create_user: "Crear usuario",
    create_pointSale: "Crear local de recogida",
    create_product: "Crear producto",
    create_service: "Crear servicio",
    create_categories: "Crear categoría",
    create_invoice: "Crear factura",
    create_offer: "Create Offer",
    create_order_status: "Create Status",
    create_category: "Create Category",
    update_offer: "Edit Offert",
    notification: "Show the availability of products in stock",
    low_availability: "Umbrar de la baja disponibilidad a mostrar en el producto este campo es requerido",
    not_available_count_buy: "No ha seleccionado la cantidad a comprar o no tiene disponibilidad el producto",
    btn_filter_clear: "Clear filters",
    actions_search: "Buscar elementos",
    delete_product:"Eliminar productos",
    not_visibility_product:"Hacer no visible los productos",
    visibility_product:"Hacer visible los productos",
    select_all:"Seleccionar todos",
    select_one:"Selecctonar {element}",
    delete_offers: "Delete offerts"
}